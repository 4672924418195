import { get } from "lodash";
import { combineReducers } from "redux";
import * as types from "./types";

import { createReducer } from "../../utils";
import JWTService from "./../../../services/jwt.service";
import StorageService from "./../../../services/localstorage.service"

const authReducer = createReducer(false)({
    [types.LOGOUT]: () => false,
    [types.AUTHENTICATED]: () => true,
    [types.AUTHORIZATION_FAILED]: () => {
        StorageService.removeItem('isAuthenticated');
        StorageService.removeItem('user');
        StorageService.removeItem('token');
        return false
    }
});

// const fisherManSignUpReducer = createReducer(null)({
//     [types.FISHERMAN_SIGNUP1_COMPLETED]: (state, action) => {
//         const signUpDetails = action.payload.payload;
//         return signUpDetails;
//     }
// });

// const fishermanVesselReducer = createReducer(null)({
//     [types.FISHERMAN_SIGNUP2_COMPLETED]: (state, action) => {
//         const vesselDetails = action.payload;
//         return vesselDetails
//     }
// })

const buyerListReducer = createReducer([])({
    [types.BUYERS_LIST_COMPLETED]: (state, action) => {
        return get(action, 'payload.payload.buyerlist', [])
    }

});
const buyerDetailReducer = createReducer({})({
    [types.BUYER_DETAILS_COMPLETED]: (state, action) => {
        return get(action, 'payload.payload', {})
    }

});

const activeBuyerCountReducer = createReducer(0)({
    [types.BUYERS_LIST_COMPLETED]: (state, action) => {
        return get(action, 'payload.payload.activeBuyerCount', [])
    }

});
const allBuyerCountReducer = createReducer(0)({
    [types.BUYERS_LIST_COMPLETED]: (state, action) => {
        return get(action, 'payload.payload.allBuyerCount', [])
    }

});

const speciesListReducer = createReducer([])({
    [types.SPECIES_LIST_COMPLETED]: (state, action) => {
        return get(action, 'payload.payload', [])
    }

});

const initializeSessionReducer = createReducer(null)({
    [types.INITIALIZE]: (state, action) => {
        const user = action.payload.user;
        const tokens = action.payload.tokens;
        StorageService.setItem('isAuthenticated', true);
        // StorageService.setItem('user', user);
        StorageService.setItem('user', action.payload.adminData);

        JWTService.saveToken(tokens);
        return { user, tokens }
    },
    [types.DESTROY]: () => {
        StorageService.removeItem('isAuthenticated');
        StorageService.removeItem('user');
        JWTService.destroyToken();
        return null;
    }
});

export default combineReducers({
    isAuthenticated: authReducer,
    session: initializeSessionReducer,
    buyerlist: buyerListReducer,
    activeBuyer: activeBuyerCountReducer,
    allBuyer: allBuyerCountReducer,
    buyerDetail: buyerDetailReducer,
    speciesListItems: speciesListReducer
});
