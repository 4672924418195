import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { multilanguage } from "redux-multilanguage";
import HookForm from "../../HookForm/HookForm";

import FileUpload from "../FileUpload/FileUpload";
import TextField from "../TextField/TextField";

import Constant from "../../../util/constant";
import { showToast } from "../../../state/ducks/utils/operations";

import "./Profile.scss";
import CustomButton from "../CustomButton/CustomButton";
import TextFieldNoRequired from "../TextFieldNoRequired/TextFieldNoRequired";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import {
  updateProfileDetails,
  getProfileDetails,
  changePassword,
} from "../../../state/ducks/profile/operations";
import { getItem, setItem } from "../../../services/localstorage.service";

const Profile = (props) => {
  let { strings } = props;
  let [image, setImage] = useState();
  const [busy, setBusy] = React.useState(false);
  let [showImageName, setShowImageName] = useState();
  const [isError, setisError] = useState(false);
  const [changeform, setResetForm] = useState();
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setshowConfirmPass] = useState(false);
  const [showOldPass, setShowOldPass] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [email, setEmail] = useState(props?.email);
  const [imagePath, setImagePath] = useState("");
  const [userForm, setUserForm] = useState(0);
  const userDetails = getItem("userDetails");
  const [userDetail, setUserDetail] = useState();
  const [searchOldPassword, setSearchOldPassword] = useState('');

  const onImageChange = (acceptedFiles) => {
    setShowImageName(acceptedFiles[0]);
    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
      setImage(event.target.result);
    });
    reader.readAsDataURL(acceptedFiles[0]);
  };

  useEffect(() => {
    getProfileDetailsData();
  }, []);

  const getProfileDetailsData = async () => {
    try {
      await props
        .getProfileDetails()
        .then((res) => {
          setItem("userDetails", res?.payload);
        })
        .catch((err) => {
          setBusy(false);
          props.showToast({
            message: get(
              err,
              "response.data.message",
              strings["SOMETHING_WENT_WRONG"]
            ),
            type: "error",
          });
        });
    } catch (error) {
      setBusy(false);
    }
  };

  const onUpdateSubmit = async (e) => {
    const params = new FormData();
    params.append("profileImage", showImageName);
    params.append("Name", e.profileName);
    try {
      setBusy(true);
      let response = await props.updateProfileDetails(params);
      setItem("userDetails", response?.payload);
      setTimeout(() => {
        setBusy(false);
        props.showToast({
          message: response?.message,
          type: "success",
        });
      }, 1000);
    } catch (err) {
      setBusy(false);
    }
  };
  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
    }
  };
  const { setError } = useForm();

  const onFormSubmit = async (data) => {
    // let params = {
    //   oldPassword: data.oldpassword,
    //   newPassword: data.password
    // }
    setBusy(true);
    data?.oldpassword &&
      (await props
        .changePassword({
          oldPassword: data.oldpassword,
          newPassword: data.password,
        })
        .then((res) => {
          setTimeout(() => {
            props.showToast({
              message: res.message,
              type: "success",
            });
          }, 1000);
          setShowChangePassword(false);
          setBusy(false);
          props.history.push("/login");
        })
        .catch((err) => {
          setTimeout(() => {
            setBusy(false);
            props.showToast({
              message: err.response.data.message,
              type: "error",
            });
          }, 1000);
          setBusy(false);
        }));
  };

  const onLogoutPress = () => {
    setShowLogoutModal(false);
    props.history.push("/login");
    // setTimeout(() => {
    //   props.showToast({
    //     message: 'message',
    //     type: "success",
    //   });
    // }, 1000);
  };

  const checkValidFile = (file) => {
    const fileType = file[0].type;
    if (
      fileType.includes("jpg") ||
      fileType.includes("png") ||
      fileType.includes("jpeg")
    ) {
      return true;
    } else {
      return false;
    }
  };
  const profileForm = {
    profileName: {
      name: "profileName",
      validate: {
        required: {
          value: true,
          message: strings["FULL_NAME_EMPTY"],
        },
        pattern: {
          value: Constant.REGEX.ALPHANUMERIC,
          message: strings["ONLY_ALPHABETS_NUMBER_ACCENTS"],
        },
      },
    },
    email: {
      name: "email",
      validate: {
        required: {
          value: true,
          message: strings["EMAIL_REQUIRED"],
        },
        pattern: {
          value: Constant.REGEX.EMAIL,
          message: strings["EMAIL_INVALID"],
        },
      },
    },
  };
  const multiErrorFields = [
    { length: strings["MINIMUM_8"] },
    { hasUppercase: strings["UPPERCASELOWERCASE"] },
    { hasSpecialChar: strings["SPECIAL"] },
    { hasNumbers: strings["NUMERICAL"] },
    // { 'hasName': strings["PASSWORD_NOT_NAME"] },
  ];
  const changespassword = {
    oldpassword: {
      name: "oldpassword",
      validate: {
        required: {
          value: true,
          message: strings["NEW_PASSWORD_REQUIRED"],
        },
      },
    },

    newpassword: {
      name: "newpassword",
      validate: {
        required: {
          value: true,
          message: strings["NEW_PASSWORD_REQUIRED"],
        },
        validate: {
          length: (value) =>
            (value && value.length >= 8) || strings["MINIMUM_8"],
          hasUppercase: (value) =>
            (value && value.match(Constant.REGEX.LOWERCASEUPPERCASE)) ||
            strings["UPPERCASELOWERCASE"],
          hasSpecialChar: (value) =>
            (value && value.match(Constant.REGEX.SPECIALCHARACTERS)) ||
            strings["SPECIAL"],
          hasNumbers: (value) =>
            (value && value.match(Constant.REGEX.NUMBER)) ||
            strings["NUMERICAL"],
        },
      },
    },
    password: {
      name: "password",
      validate: {
        required: {
          value: true,
          message: strings["CONFIRM_PASSWORD_REQUIRED"],
        },
      },
    },
  };

  let imageWord = () => {
    if (userDetails) {
      const wordWrapName = userDetails && userDetails?.Name;
      let w = wordWrapName
        ?.split(" ")
        .map((word) => word.charAt(0).toUpperCase())
        .join("")
        .trim(2);
      let y = w.substring(0, 2);
      return y;
    }

    return "";
  };

  // useEffect(() => {
  //   const params = {
  //     oldPassword: searchOldPassword,
  //     newPassword: ''
  //   }
  //   const delayDebounceFn = setTimeout(async () => {
  //     let response = props.changePassword(params);
  //     if (!response?.data?.payload?.status) {
  //       changeform?.setError(changespassword.oldpassword.name, { type: 'manual', message: response.data.message });
  //     }
  //   }, 1000)

  //   return () => clearTimeout(delayDebounceFn)
  // }, [searchOldPassword, changeform])

  return (
    <div className="ProfilePage">
      {!showChangePassword ? (
        <div className="editprofile">
          <HookForm
            defaultForm={{}}
            init={(form) => setUserForm(form)}
            onSubmit={(e) => onUpdateSubmit(e)}
          >
            {(formMethod) => {
              return (
                <>
                  <div className="fileInput">
                    <Controller
                      defaultValue=""
                      render={({ onChange }) => (
                        <FileUpload
                          onDrop={(acceptedFiles) => {
                            if (checkValidFile(acceptedFiles)) {
                              onChange(acceptedFiles);
                              onImageChange(acceptedFiles);
                            }
                          }}
                        >
                          <div className="imgBoxEmpty">
                            {(userDetails && userDetails?.image) || image ? (
                              <>
                                <div className="uploadedImg">
                                  <img
                                    src={image ? image : userDetails?.image}
                                    alt="viewImg"
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="uploadedImg">
                                  <div className="ProifleName">
                                    {imageWord()}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="change_txt">Upload</div>
                        </FileUpload>
                      )}
                      name="image"
                      control={formMethod.control}
                    />
                  </div>
                  <div className="input_label">Name</div>
                  <TextField
                    formMethod={formMethod}
                    rules={profileForm.profileName.validate}
                    name={profileForm.profileName.name}
                    errors={formMethod?.errors}
                    autoFocus={true}
                    type="text"
                    defaultValue={userDetails && userDetails.Name}
                    autoComplete="on"
                    placeholder="Full name"
                    maxLength={50}
                  />
                  <div className="input_label">Email address</div>
                  <TextFieldNoRequired
                    formMethod={formMethod}
                    rules={profileForm.email.validate}
                    name={profileForm.email.name}
                    errors={formMethod?.errors}
                    autoFocus={true}
                    type="text"
                    defaultValue={userDetails && userDetails.email}
                    autoComplete="on"
                    placeholder={strings["EMAIL_ADDRESS"]}
                    disabled
                  />
                  <div className="custombtnfield">
                    <CustomButton
                      type="submit"
                      title={strings["UPDATE"]}
                      disabled={!formMethod?.formState.isValid}
                      loading={busy}
                    />
                    <CustomButton
                      type="button"
                      className="outline_btn"
                      title={strings["CHANGEPASSWORD_BT"]}
                      onClick={() => {
                        setShowChangePassword(true);
                        props.onHeaderChange("Change password");
                      }}
                    />
                    <CustomButton
                      type="button"
                      className="outline_btn"
                      onClick={() => setShowLogoutModal(!showLogoutModal)}
                      title={strings["LOGOUT"]}
                    />
                  </div>
                </>
              );
            }}
          </HookForm>
        </div>
      ) : (
        <div className="change_pasword">
          <HookForm
            defaultForm={{}}
            init={(changeform) => setResetForm(changeform)}
            onSubmit={onFormSubmit}
          >
            {(formMethod) => {
              return (
                <div className="form">
                  <div className="top_title_row">
                    <div className="change_password_title">Change password</div>
                    <div className="signfield">
                      <CustomButton
                        type="submit"
                        title="Update"
                        disabled={!formMethod?.formState.isValid}
                        loading={busy}
                      />
                      <CustomButton
                        type="button"
                        className="outline_btn"
                        title="Cancel"
                        onClick={() => {
                          setShowChangePassword(false);
                          props.onHeaderChange("Profile");
                        }}
                      />
                    </div>
                  </div>
                  <TextField
                    formMethod={formMethod}
                    rules={changespassword.oldpassword.validate}
                    multiErrorFields={multiErrorFields}
                    errors={formMethod?.errors}
                    name={changespassword.oldpassword.name}
                    iconError={formMethod?.errors}
                    type={showOldPass ? "text" : "password"}
                    placeholder="Old Password"
                    iconClass={showOldPass ? "icon-eye-show" : "icon-eye-hide"}
                    onKeyDown={onKeyDown}
                    onChange={(e) => {
                      let value = e.target.value;
                      e.target.value = value.replaceAll(" ", "");
                      formMethod.setValue('oldpassword', e.target.value);
                      // formMethod.watch("password") &&
                      // formMethod.trigger("password");
                      setSearchOldPassword(e.target.value)
                    }}
                    onIconClick={() => setShowOldPass(!showOldPass)}
                    iconRightShow={true}
                    defaultValue={""}
                  />
                  <TextField
                    formMethod={formMethod}
                    rules={changespassword.newpassword.validate}
                    multiErrorFields={multiErrorFields}
                    errors={formMethod?.errors}
                    name={changespassword.newpassword.name}
                    iconError={formMethod?.errors}
                    type={showPass ? "text" : "password"}
                    placeholder="New Password"
                    iconClass={showPass ? "icon-eye-show" : "icon-eye-hide"}
                    onKeyDown={onKeyDown}
                    onChange={() =>
                      formMethod.watch("password") &&
                      formMethod.trigger("password")
                    }
                    onIconClick={() => setShowPass(!showPass)}
                    iconRightShow={true}
                    defaultValue={""}
                  />
                  <TextField
                    formMethod={formMethod}
                    rules={{
                      required: {
                        value: true,
                        message: strings["CONFIRM_PASSWORD_REQUIRED"],
                      },
                      validate: {
                        matchPassword: (value) =>
                          (value &&
                            value === formMethod.watch("newpassword")) ||
                          strings["BOTHNOTMATCH"],
                      },
                    }}
                    name={changespassword.password.name}
                    errors={formMethod?.errors}
                    iconError={formMethod?.errors}
                    type={showConfirmPass ? "text" : "password"}
                    placeholder="Confirm Password"
                    iconClass={
                      showConfirmPass ? "icon-eye-show" : "icon-eye-hide"
                    }
                    onChange={() =>
                      formMethod.watch("newpassword") &&
                      formMethod.trigger("newpassword")
                    }
                    onKeyDown={onKeyDown}
                    onIconClick={() => setshowConfirmPass(!showConfirmPass)}
                    iconRightShow={true}
                  />
                </div>
              );
            }}
          </HookForm>
        </div>
      )}
      <ConfirmModal
        modalTitle={strings["LOGOUT"]}
        leftBtnTitle={strings["YES_LOGOUT"]}
        rightBtnTitle={strings["NO_CANCEL"]}
        showModal={showLogoutModal}
        onHide={() => setShowLogoutModal(false)}
        onProcced={() => {
          onLogoutPress();
        }}
        modalContent={false}
        className={"confirm-logout"}
      >
        <p>Are you sure, you want to logout? </p>
      </ConfirmModal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  showToast,
  updateProfileDetails,
  getProfileDetails,
  changePassword,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(multilanguage(Profile))
);