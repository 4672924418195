import { isCheckValueAndSetParams } from '../../../services/helper.service';
import * as types from './types';

export const getBusinessList = (id, page, limit, search) => (
    {
        type: types.GET_BUSINESS_LIST_COMPLETED,
        meta: {
            async: true,
            blocking: false,
            path: `business/getBusinessList?categoryId=${id}&sortBy=status:desc&page=${page}&limit=${limit}${isCheckValueAndSetParams("&search=", search)}`,
            method: 'GET',
        }
    })
export const getAllBusinessList = () => (
    {
        type: types.GET_ALL_BUSINESS_LIST,
        meta: {
            async: true,
            blocking: false,
            path: `business/getAllBusinesses`,
            method: 'GET'
        }
    }
)

export const deleteBusiness = (id) => (
    {
        type: types.DELETE_BUSINESS_COMPLETED,
        meta: {
            async: true,
            blocking: false,
            path: `business/delete?id=${id}`,
            method: 'DELETE'
        }
    })

export const statusUpdate = (id, payload) => (
    {
        type: types.BUSINESS_STATUS_UPDATE_COMPLETED,
        meta: {
            async: true,
            blocking: false,
            path: `business/updateStatus?id=${id}`,
            method: 'PUT',
            body: payload
        }
    })


export const updateSideMenu = (updateSideMenu = false) => ({
    type: types.UPDATE_SIDEMENU,
    meta: {
        updateSideMenu: updateSideMenu,
    }
})

export const updateBusinessList = (updateBusinessList = false) => ({
    type: types.UPDATE_BUSINESS_LIST,
    meta: {
        updateBusinessList: updateBusinessList
    }
})

export const updateBusiness = (id, payload) => (
    {
        type: types.UPDATE_BUSINESS_COMPLETED,
        meta: {
            async: true,
            blocking: false,
            path: `business/update?id=${id}`,
            method: 'PUT',
            body: payload
        }
    }
)

export const addBusiness = (payload) => (
    {
        type: types.ADD_BUSINESS_COMPLTED,
        meta: {
            async: true,
            blocking: false,
            path: `business/add`,
            method: 'POST',
            body: payload
        }
    }
)

export const getServiceDetails = (id) => (
    {
        type: types.GET_BUSINESS_ITEM_COMPLETED,
        meta: {
            async: true,
            blocking: false,
            path: `business/getBusinessDetails?id=${id}`,
            method: 'GET'
        }
    }
)
