
export const LOGIN = "auth/LOGIN";
export const LOGIN_FAILED = "auth/LOGIN_FAILED";
export const LOGIN_COMPLETED = "auth/LOGIN_COMPLETED";

export const AUTHENTICATED = "auth/AUTHENTICATED";
export const AUTHORIZATION_FAILED = "auth/AUTHORIZATION_FAILED";

export const BUYERS_LIST = "BUYERS_LIST";
export const BUYERS_LIST_COMPLETED = "BUYERS_LIST_COMPLETED";
export const BUYERS_LIST_FAILED = "BUYERS_LIST_FAILED";
export const ENABLE_BUYER = "ENABLE_BUYER";
export const DISABLE_BUYER = "DISABLE_BUYER";
export const BUYER_DELETED = "BUYER_DELETED";

export const BUYER_DETAILS = "BUYER_DETAILS";
export const BUYER_DETAILS_COMPLETED = "BUYER_DETAILS_COMPLETED";
export const BUYER_DELETED_FAILED = "BUYER_DELETED_FAILED";

export const FISHERMAN_VERIFY = "FISHERMAN_VERIFY";
export const UPDATE_COMMISSION = "UPDATE_COMMISSION"

export const SPECIES_LIST = "SPECIES_LIST"
export const SPECIES_LIST_COMPLETED = "SPECIES_LIST_COMPLETED"

export const LOGOUT = "auth/LOGOUT";
export const LOGOUT_FAILED = "auth/LOGOUT_FAILED";
export const LOGOUT_COMPLETED = "auth/LOGOUT_COMPLETED";

export const REFRESH_TOKEN = "auth/REFRESH_TOKEN";
export const REFRESH_TOKEN_FAILED = "auth/REFRESH_TOKEN_FAILED";
export const REFRESH_TOKEN_COMPLETED = "auth/REFRESH_TOKEN_COMPLETED";

export const ENABLE_DISABLE_SPECIES = "ENABLE_DISABLE_SPECIES"
export const DELETE_SPECIES = "DELETE_SPECIES"

export const DESTROY = "auth/DESTROY_SESSION";
export const INITIALIZE = "auth/INITIALIZE_SESSION";