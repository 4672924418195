import React, { useState, useEffect } from "react";
import './CustomToggleButton.scss';

const CustomToggleButton = (props) => {
    let { onCheckChange } = props
    const [checked, setChecked] = useState(props.checked)
    let { id } = props

    useEffect(() => {
        setChecked(props.checked)
    }, [props.checked])

    const onToggleButtonChecked = (e) => {
        return (
            onCheckChange && onCheckChange(e.target.checked)
        )
    }
    return (
        <div className="CustomToggleButton-container">
            <label className="CustomToggleButton-main">
                <input type="checkbox" id={id} checked={checked} onChange={onToggleButtonChecked} />
                <span className="slider-button round"></span>
            </label>
        </div>
    )
}

//minro change
CustomToggleButton.defaultProps = {
    id: '',
    checked: false,
    labelTitle: '',
}

export default CustomToggleButton;