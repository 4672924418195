import moment from "moment";
import { isEmpty, get } from "lodash"
export const isValidValue = (value) => {
  if (typeof (value) === "undefined" || value === null || value === "") {
    return false
  }
  return true
}
export const getReleaseTime = timestamp => {
  timestamp = timestamp * 1000;
  let dt = new Date(timestamp);
  return `${moment(dt).format("DD MMM, YYYY")} at ${moment(dt).format(
    "hh:mm A"
  )}`;
};

export const getTimelineTime = timestamp => {
  timestamp = timestamp * 1000;
  const dt = new Date(timestamp);
  const now = new Date().getTime();
  if (now - timestamp < 1000 * 60 * 60 * 24 * 5) {
    return moment(dt).fromNow();
  } else {
    return `${moment(dt).format("DD MMM, YYYY")} at ${moment(dt).format(
      "hh:mm A"
    )}`;
  }
};

export const UID = () => {
  let array = new Uint32Array(8);
  window.crypto.getRandomValues(array);
  let str = "";
  for (let i = 0; i < array.length; i++) {
    str += (i < 2 || i > 5 ? "" : "-") + array[i].toString(16).slice(-4);
  }
  return str;
};

export const dateFormat = (date, format = 'YYYY/MM/DD') => {
  return moment(date).format(format)
}


export const getParams = (params) => {
  if (isEmpty(params)) return ''
  let query = []
  Object.keys(params).forEach(key => {
    if (isValidValue(params[key])) {
      query.push(`${key}=${params[key]}`)
    }
  })
  return query.join('&')
}

export function isCheckValueAndSetParams(params, value) {
  if (typeof (value) === "undefined" || value === null || value === "") {
    return ''
  }
  return params + value
}