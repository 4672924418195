import React from 'react';
import { Link } from 'react-router-dom';
import CustomToggleButton from '../UI/CustomToggleButton/CustomToggleButton';
import './CustomSideBar.scss';

const CustomSideBar = (props) => {
  let { title, showSidebar, checked, onCheckChange, onClickDelete, className, actionHeader } = props;
  return (
    <div className={(showSidebar ? "CustomSideBar show " + className : "CustomSideBar " + className)}>
      <div className={"sidebarInner"}>
        <div className="headerBar">
          <button onClick={() => props.onHide()} className="icon-back"></button>
          <span className="title">{title}</span>
          <Link onClick={() => props.onHide()} to="#" className="iconClose">
            <i className="icon-close"></i>
          </Link>
        </div>
        {actionHeader &&
          <div className="action_header">
            <button onClick={onClickDelete} className="icon-delete"></button>
            <CustomToggleButton id="" checked={checked} onCheckChange={onCheckChange} />
          </div>
        }
        <div className="centerContentBox">
          {props.children}
        </div>
      </div>
    </div >
  )
}

export default (CustomSideBar);
