import React, { useState, useEffect } from 'react';
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import { withRouter, Link } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';

import Profile from '../../components/UI/Profile/Profile';
import CustomSideBar from '../../components/CustomSideBar/CustomSideBar';
import AppConfigurations from '../../components/UI/AppConfigurations/AppConfigurations';

import { logout } from "../../state/ducks/admin/actions";
import { showToast, showPopup } from "../../state/ducks/utils/operations";
import useWindowDimension from "../../hooks/useWindowDimension";

import "./AdminHeader.scss";
import { getItem } from '../../services/localstorage.service';


const AdminHeader = (props) => {
  let { strings, onClickCategory } = props;
  const dimensions = useWindowDimension();
  let [image, setImage] = useState();
  const [sidebarHeaderHeight, SetSidebarHeaderHeight] = useState("");
  let [showProfile, setShowProfile] = useState(false)
  let [showAppConfigurations, setShowAppConfigurations] = useState(false)
  const [busy, setBusy] = useState(false)
  const [headerTitle, setHeaderTitle] = useState("Profile")
  const userDetails = getItem('userDetails')

  const [isEventSelected, setIsEventSelected] = useState(true)


  useEffect(() => {
    SetSidebarHeaderHeight(document.getElementsByClassName("headerBar")[0].offsetHeight);
  }, []);
  useEffect(() => {
    if (props.isPopup) {
      setShowAppConfigurations(false);
      setShowProfile(false)
    }
  }, [props.isPopup])
  let adminName = localStorage.getItem("NAME");


  let activeMenu = '';
  if (props.location.pathname.indexOf('service') !== -1) {
    activeMenu = 'service'
  }
  else if (props.location.pathname.indexOf('profile') !== -1 || props.location.pathname.indexOf('changepassword') !== -1) {
    activeMenu = 'profile'
  }
  else {
    activeMenu = 'profile'
  }

  let imageWord = () => {

    if (userDetails) {
      const wordWrapName = userDetails.Name
      let w = wordWrapName?.split(' ')
        .map((word) => word.charAt(0).toUpperCase()).join('').trim(2)
      let y = w.substring(0, 2)
      return y

    }

    return ""



  }



  // const onKeyDown = (e) => {
  //   e.keyCode === 13 && onFormSubmit();
  // }

  // const onFormSubmit = () => {
  //   setBusy(true)
  //   setTimeout(() => {
  //     setBusy(false);
  //     props.showToast({ message: 'Your passwords has been changed successfully', type: 'success' })
  //     props.history.push('/login')
  //   }, 1000)
  // }

  return (
    <div className={showProfile || showAppConfigurations ? "header headershow" : "header"} >
      <Navbar>
        {/* <Navbar.Brand href="/dashboard" className='navbar-logo'>
          <img src={Constant.IMAGESURL.LOGO} title="" alt="" className="logo" />
        </Navbar.Brand> */}
        <Navbar.Collapse id="header-nav"  >
          <Nav activeKey={activeMenu}>
            {/* <Nav.Link eventKey="service" as={Link} to="/service"><i className='icon icon-business'></i>Service</Nav.Link> */}
            <Nav.Link eventKey="service" as={Link} to={{ pathname: '/service', state: { serviceCount: isEventSelected } }}>
              <i className='icon icon-business'></i>Service</Nav.Link>
            <Nav.Link eventKey="profile" as={Link} to="/event"><i className='icon icon-event'></i>Event</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <div className='profiledropdown'>
          <div className="profilebtn">
            <button className="app_menu" onClick={() => {
              props.showPopup();
              setShowAppConfigurations(true)
            }}>
              <span className="txt">App configuration</span>
              <div className="icon-setting"></div>
            </button>
            <button className="avtar-name" onClick={() => {
              if (showAppConfigurations) {
                props.showPopup();
                setShowAppConfigurations(false)
                setShowProfile(true)
              } else {
                props.showPopup();
                setShowProfile(true)
              }
            }}>
              {/* <p>{props?.adminDetails?.fullName}</p> */}
              <span>Hi, {userDetails && userDetails?.Name}</span>
              <div className="icon-arrow-right"></div>
            </button>
          </div>
          <div className="avtar" >
            {/* <img src="https://res.cloudinary.com/zudu/image/upload/v1631272249/Zeadog/Zeadog-web/Static-images/avtar.jpg" title="" alt="" className="logo" /> */}
            {/* <span className="sortName">MD</span> */}
            {userDetails && userDetails?.image || image ?
              <img src={image ? image : userDetails?.image} alt="viewImg" />
              :
              <span className="sortName">{imageWord()}</span>
            }
          </div>
        </div>
      </Navbar >

      <CustomSideBar
        title={headerTitle}
        showSidebar={showProfile}
        onHide={() => setShowProfile(false)}
        className="profile"
      >
        <Scrollbars style={{ height: (dimensions.height - sidebarHeaderHeight) }}>
          <Profile onHeaderChange={(header) => setHeaderTitle(header)} />
        </Scrollbars>
      </CustomSideBar>
      <CustomSideBar
        title="App Configurations"
        showSidebar={showAppConfigurations}
        onHide={() => setShowAppConfigurations(false)}
        className="AppConfigurations"
      >
        <Scrollbars style={{ height: (dimensions.height - sidebarHeaderHeight) }}>
          <AppConfigurations />
        </Scrollbars>
      </CustomSideBar>
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
    adminDetails: state?.auth?.session?.user,
    isPopup: state?.utils?.hidePopup
  };
};

const mapDispatchToProps = {
  showToast,
  logout,
  showPopup,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(multilanguage(AdminHeader))
);
