import React, { Suspense, useEffect } from 'react';
import { connect } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import routes from "./routes";
import { hideToast } from "./state/ducks/utils/operations"
import Loader from "./components/Loading/Loading"
import ToastComponent from "./components/UI/ToastComponent"
import { multilanguage, loadLanguages } from "redux-multilanguage";


import 'bootstrap/dist/css/bootstrap.min.css';

import './App.scss';

function App(props) {
  const { toast, hideToast, loader } = props;

  useEffect(() => {

    props.dispatch(
      loadLanguages({
        languages: { //from merchant supported languages
          en: require("./languages/en.json"),
          spn: require("./languages/spn.json")
        }
      })
    );
  });

  return (
    <div className="App" >
      <Suspense fallback={<></>}>
        <ToastComponent show={toast.show} message={toast.message} type={toast.type} onClose={hideToast} flag={toast.flag} />
        <Loader show={loader} />
        <BrowserRouter>
          <Switch>
            {routes.map((route, index) => {
              return <Route
                key={index}
                exact={route.exact}
                path={route.path}
                component={route.component}
              />
            })}
          </Switch>
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    toast: state.utils.toast,
    loader: state.utils.loader
  }
};

const mapDispatchToProps = {
  hideToast
};

export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(App));
