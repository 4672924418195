export const GET_CATEGORY_LIST = "category/GET_CATEGORY_LIST";
export const GET_CATEGORY_LIST_FAILED = "category/GET_CATEGORY_LIST_FAILED";
export const GET_CATEGORY_LIST_COMPLETED = "category/GET_CATEGORY_LIST_COMPLETED";

//update Category List..

export const STATUS_UPDATE_CATEGORY = "category/STATUS_UPATE_CATEGORY";
export const STATUS_UPDATE_CATEGORY_FAILED = "category/STATUS_UPDATE_CATEGORY_FAILED";
export const STATUS_UPDATE_CATEGORY_COMPLETED = "category/STATUS_UPDATE_CATEGORY_COMPLETED";


//Delete Category

// localhost:4000/v1/category/delete?id=615431963c297a4a9d5e1c89

export const DELETE_CATEGORY = "category/DELETE_CATEGORY";
export const DELETE_CATEGORY_FAILED = "category/DELETE_CATEGORY_FAILED";
export const DELETE_CATEGORY_COMPLETED = "category/DELETE_CATEGORY_COMPLETED";

// update Category


export const UPDATE_CATEGORY = "category/UPDATE_CATEGORY";
export const UPDATE_CATEGORY_FAILED = "category/UPDATE_CATEGORY_FAILED";
export const UPDATE_CATEGORY_COMPLETED = "category/UPDATE_CATEGORY_COMPLETED";


//Add Category

export const ADD_CATEGORY = "category/ADD_CATEGORY";
export const ADD_CATEGORY_FAILED = "category/ADD_CATEGORY_FAILED";
export const ADD_CATEGORY_COMPLETED = "category/ADD_CATEGORY_COMPLETED"

// Upload Catagory


export const GET_CATEGORY_LIST_FOR_COLOR = "category/GET_CATEGORY_LIST_FOR_COLOR";
export const GET_CATEGORY_LIST_FOR_COLOR_FAILED = "category/GET_CATEGORY_LIST_FOR_COLOR_FAILED";
export const GET_CATEGORY_LIST_FOR_COLOR_COMPLETED = "category/GET_CATEGORY_LIST_FOR_COLOR_COMPLETED";