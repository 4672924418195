import {
    login as loginAction,
    signup,
    fisherman_signup1,
    fisherman_signup2,
    fisherman_signup_completed,
    getports,
    suggestPort,
    refreshToken,
    resetPassword,
    authenticated,
    destroySession,
    forgotPassword,
    initializeSession,
    verifyResetPasswordLink
} from "./actions";

const login = (payload) => {
    return async (dispatch) => {
        let response = await dispatch(loginAction(payload));
        return response
    };
}

export {
    login,
    refreshToken,
    resetPassword,
    authenticated,
    signup,
    fisherman_signup1,
    fisherman_signup2,
    fisherman_signup_completed,
    getports,
    suggestPort,
    destroySession,
    forgotPassword,
    initializeSession,
    verifyResetPasswordLink
};
