import { getParams } from "../../../services/helper.service";
import * as types from "./types";

export const login = (payload) => (
    {
        type: types.LOGIN,
        meta: {
            async: true,
            blocking: false,
            path: "auth/login",
            method: "POST",
            body: payload
        },
    });

export const signup = (payload) => ({
    type: types.SIGNUP,
    meta: {
        async: true,
        blocking: true,
        path: `buyer/buyerregister`,
        method: "POST",
        body: payload
    },
});

export const fisherman_signup1 = (payload) => ({
    type: types.FISHERMAN_SIGNUP1,
    meta: {
        async: true,
        blocking: true,
        path: `vessel/fishermanregister`,
        method: "POST",
        body: payload
    }
})

export const fisherman_signup2 = (payload, fisherManId) => {
    return ({
        type: types.FISHERMAN_SIGNUP2,
        meta: {
            async: true,
            blocking: true,
            path: `vessel/fishermanregisterpage2/${fisherManId}`,
            method: "POST",
            body: payload
        }
    })
}

export const getports = (payload) => ({
    type: types.GETPORTS,
    meta: {
        async: true,
        blocking: true,
        path: `vessel/getports`,
        method: "GET",
        body: payload
    }
})

export const fisherman_signup_completed = (payload, id) => ({
    type: types.FISHERMAN_SIGNUP_COMPLETED,
    meta: {
        async: true,
        blocking: true,
        path: `vessel/fishermanregisterpage3/${id}`,
        method: "POST",
        body: payload
    }
})

export const suggestPort = (payload) => ({
    type: types.SUGGEST_US,
    meta: {
        async: true,
        blocking: true,
        path: `vessel/suggestports`,
        method: "POST",
        body: payload
    }
})

// export const buyer_list = (params) => {
//     return (
//         {
//             type: types.BUYERS_LIST,
//             meta: {
//                 async: true,
//                 blocking: true,
//                 path: `/admin?${getParams(params)}`,
//                 method: "GET",
//             }
//         })
// }

// export const enableBuyer = (payload) => ({
//     type: types.ENABLE_BUYER,
//     meta: {
//         async: true,
//         blocking: true,
//         path: `/admin/enabledisablebuyer`,
//         method: "PATCH",
//         body: payload
//     }
// })



// export const deletedBuyer = (id, payload) => ({
//     type: types.BUYER_DELETED,
//     meta: {
//         async: true,
//         blocking: true,
//         path: `/admin/buyerdeleted/${id}`,
//         method: "PATCH",
//         body: payload
//     }
// })


export const fisherman_list = (params) => {
    return (
        {
            type: types.FISHERMAN_LIST,
            meta: {
                async: true,
                blocking: true,
                path: `/admin/fishermanlist?${getParams(params)}`,
                method: "GET",
            }
        })
}

export const deletedFisherman = (id, payload) => ({
    type: types.FISHERMAN_DELETED,
    meta: {
        async: true,
        blocking: true,
        path: `/admin/fishermandeleted/${id}`,
        method: "PATCH",
        body: payload
    }
})

export const fishermanDetails = (id) => {
    return (
        {
            type: types.FISHERMAN_DETAILS,
            meta: {
                async: true,
                blocking: true,
                path: `/admin/getfishermanbyid/${id}`,
                method: "GET",
            }
        })
}


export const enableDisableFisherman = (id, payload) => ({
    type: types.ENABLE_DISABLE_FISHERMAN,
    meta: {
        async: true,
        blocking: true,
        path: `/admin/enabledisablefisherman/${id}`,
        method: "PATCH",
        body: payload
    }
})


export const authenticated = () => ({
    type: types.AUTHENTICATED,
});

export const initializeSession = (payload) => ({
    type: types.INITIALIZE,
    payload
});

export const destroySession = () => ({
    type: types.DESTROY
});

export const refreshToken = () => ({
    type: types.REFRESH_TOKEN
})

export const forgotPassword = (payload) => ({
    type: types.FORGOT_PASSWORD,
    meta: {
        async: true,
        blocking: true,
        path: "auth/forgot-password",
        method: "POST",
        body: payload
    },
});

export const resetPassword = (token, payload) => ({
    type: types.RESET_PASSWORD,
    meta: {
        async: true,
        blocking: true,
        path: `auth/reset-password?token=${token}`,
        method: "POST",
        body: payload
    },
});

export const verifyResetPasswordLink = (payload) => ({
    type: types.VERIFY_RESET_PASSWORD_LINK,
    meta: {
        async: true,
        blocking: true,
        path: `auth/checkResetLink`,
        body: payload,
        method: "POST"
    },
});
