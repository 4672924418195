import React, { useState } from 'react';
import { Fade, Modal } from 'react-bootstrap';
import CustomButton from '../CustomButton/CustomButton';
import './ConfirmModal.scss';


const ConfirmModal = (props) => {

  let [btnVisible, setVisible] = useState(props.btnVisible);
  let [modalContent, setModalContent] = useState(props.modalContent);

  return (
    <Modal
      className={'confirmModal ' + props.className}
      show={props.showModal}
      animation={Fade}
      onHide={() => props.onHide()}
      centered
    >
      <div className="icon-warning"></div>
      <div className='modal-header'>
        <p>{props.modalTitle}</p>
      </div>

      <Modal.Body>
        {modalContent &&
          <p className="modalContent">{props.modalDescription}</p>
        }
        {props?.children}
      </Modal.Body>

      {btnVisible && <div className='modal-footer'>
        <CustomButton type="submit" className="outline_btn" title={props.leftBtnTitle} onClick={() => { props.onProcced(true) }} />
        <CustomButton type="submit" title={props.rightBtnTitle} onClick={() => { props.onHide(false) }} />
      </div>}
    </Modal>
  )

}

ConfirmModal.defaultProps = {
  className: 'confirmModal',
  btnVisible: true,
  modalContent: true
}

export default (ConfirmModal);