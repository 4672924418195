const Constant = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  REGEX: {
    EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    SPECIALCHARACTERS: /[!@#\$%\^\&*\)\(+=._-]/g,
    NUMBER: /[0-9]/,
    NAME: /^[a-zA-Z\s\u00C0-\u00FF]*$/,
    NAME1: /^[a-zA-Z\-\s\u00C0-\u00FF]*$/,
    ALPHANUMERIC: /^[a-zA-Z0-9\-\~\s\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F]+$/i,
    LOWERCASEUPPERCASE: /[a-z].*[A-Z]|[A-Z].*[a-z]/,// eslint-disable-next-line
    NUMERIC: /^\d*\.?\d*$/,
    NUMONLY: /^\d*$/,
    URL: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[A-Za-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
    ASCII: 'xxx[\x00-\x7F]+xxx',
  },
  IMAGESURL: {
    AUTHBANNER: 'https://res.cloudinary.com/zudu/image/upload/v1633001188/Parish_Nursing/Parish-Nursing-Web/Project-images/bg_logo.svg',
    LOGO: 'https://res.cloudinary.com/zudu/image/upload/v1633057611/Parish_Nursing/Parish-Nursing-Web/Project-images/inner_bg_logo.svg'
  },
  ROLES: {
    SUPERADMIN: "SuperAdmin",
  },
  GOOGLEMAPAPI: {
    // APIKEY: "AIzaSyDS7ikYinMzgjvPlfNon2bFlcjwofR4hFs",
    // APIKEY: "AIzaSyCTB5vBbi0F2xx_H1uhkqbhOtg3CB7vTQw"
    // APIKEY: "AIzaSyCkyEoZl1PFhLb7LOEDWvEQj_ZAZN6v7rA"
    APIKEY: "AIzaSyDYiRK54loDXDLRIxB9nl5R8fvSozD2PPM" //from zududigital acccount project zudu
  },
};
export default Constant;
