export const LOGIN = "auth/LOGIN";
export const LOGIN_FAILED = "auth/LOGIN_FAILED";
export const LOGIN_COMPLETED = "auth/LOGIN_COMPLETED";

export const SIGNUP = "SIGNUP";
export const SIGNUP_FAILED = "SIGNUP_FAILED";
export const SIGNUP_COMPLETED = "SIGNUP_COMPLETED";

export const FISHERMAN_SIGNUP1 = "FISHERMAN_SIGNUP1";
export const FISHERMAN_SIGNUP1_COMPLETED = "FISHERMAN_SIGNUP1_COMPLETED";
export const FISHERMAN_SIGNUP2 = "FISHERMAN_SIGNUP2";
export const FISHERMAN_SIGNUP2_COMPLETED = "FISHERMAN_SIGNUP2_COMPLETED";
export const FISHERMAN_SIGNUP_COMPLETED = "FISHERMAN_SIGNUP_COMPLETED";
export const SUGGEST_US = "SUGGEST_US";

export const GETPORTS = "GETPORTS";

export const AUTHENTICATED = "auth/AUTHENTICATED";
export const AUTHORIZATION_FAILED = "auth/AUTHORIZATION_FAILED";

export const BUYERS_LIST = "BUYERS_LIST";
export const ENABLE_BUYER = "ENABLE_BUYER";
export const DISABLE_BUYER = "DISABLE_BUYER";
export const BUYER_DELETED = "BUYER_DELETED";
export const BUYER_DETAILS = "BUYER_DETAILS"

export const FISHERMAN_LIST = "FISHERMAN_LIST";
export const ENABLE_DISABLE_FISHERMAN = "ENABLE_DISABLE_FISHERMAN";
export const FISHERMAN_DELETED = "FISHERMAN_DELETED";
export const FISHERMAN_APPROVE = "FISHERMAN_APPROVE";
export const FISHERMAN_DISAPPROVE = "FISHERMAN_DISAPPROVE";

export const FISHERMAN_DETAILS = "FISHERMAN_DETAILS";
export const FISHERMAN_DETAILS_COMPLETED = "FISHERMAN_DETAILS_COMPLETED";


export const LOGOUT = "auth/LOGOUT";
export const LOGOUT_FAILED = "auth/LOGOUT_FAILED";
export const LOGOUT_COMPLETED = "auth/LOGOUT_COMPLETED";

export const REFRESH_TOKEN = "auth/REFRESH_TOKEN";
export const REFRESH_TOKEN_FAILED = "auth/REFRESH_TOKEN_FAILED";
export const REFRESH_TOKEN_COMPLETED = "auth/REFRESH_TOKEN_COMPLETED";

export const FORGOT_PASSWORD = "auth/FORGOT_PASSWORD";
export const FORGOT_PASSWORD_FAILED = "auth/FORGOT_PASSWORD_FAILED";
export const FORGOT_PASSWORD_COMPLETED = "auth/FORGOT_PASSWORD_COMPLETED";

export const VERIFY_RESET_PASSWORD_LINK = "auth/VERIFY_RESET_PASSWORD_LINK";
export const VERIFY_RESET_PASSWORD_LINK_FAILED = "auth/VERIFY_RESET_PASSWORD_LINK_FAILED";
export const VERIFY_RESET_PASSWORD_LINK_COMPLETED = "auth/VERIFY_RESET_PASSWORD_LINK_COMPLETED";

export const RESET_PASSWORD = "auth/RESET_PASSWORD";
export const RESET_PASSWORD_FAILED = "auth/RESET_PASSWORD_FAILED";
export const RESET_PASSWORD_COMPLETED = "auth/RESET_PASSWORD_COMPLETED";

export const DESTROY = "auth/DESTROY_SESSION";
export const INITIALIZE = "auth/INITIALIZE_SESSION";