import React, { lazy } from "react";
import AdminLayout from "./layout/AdminLayout/index";

const AdminLogin = lazy(() =>
    import(/* webpackChunkName: "login" */ /* webpackPreload: true */ "./pages/Admin/Login"));

const AdminForgotPassword = lazy(() =>
    import(/* webpackChunkName: "forgotpassword" */ /* webpackPreload: true */ "./pages/Admin/ForgotPassword"));

const AdminResetPassword = lazy(() =>
    import(/* webpackChunkName: "resetpassword" */ /* webpackPreload: true */ "./pages/Admin/ResetPassword"));

const AdminDashboard = lazy(() =>
    import(/* webpackChunkName: "dashboard" */ /* webpackPreload: true */ "./pages/Admin/Dashboard"));

const EventManagement = lazy(() =>
    import(/* webpackChunkName: "profile" */ /* webpackPreload: true */ "./pages/Admin/EventManagement"));

const NotFound = lazy(() =>
    import(/* webpackChunkName: "notfound" */ /* webpackPreload: true */ "./pages/NotFound"));

const routes = [
    {
        path: "/",
        component: AdminLogin,
        exact: true,
    },
    {
        path: "/login",
        component: AdminLogin,
        exact: true,
    },
    {
        path: "/forgotpassword",
        component: AdminForgotPassword,
        exact: true,
    },
    {
        path: "/resetpassword",
        component: AdminResetPassword,
        exact: true,
    },

    {
        path: "/service",
        component: AdminLayout,
        exact: true
    },
    {
        path: "/event",
        component: AdminLayout,
        exact: true
    },
    {
        path: "/changepassword",
        component: AdminLayout,
        exact: true
    },
    {
        path: "*",
        component: NotFound,
        exact: true
    }
]


export const AdminLayoutRoute = [
    {
        path: "/service",
        component: AdminDashboard,
        exact: true
    },

    {
        path: "/event",
        component: EventManagement,
        exact: true
    },
]

export default routes