import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import { connect } from "react-redux";
import { Controller } from "react-hook-form";
import { multilanguage } from "redux-multilanguage";
import { Editor } from "react-draft-wysiwyg";
// import { editorState, convertFromRaw } from "draft-js";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertFromRaw,
} from "draft-js";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import HookForm from "../../HookForm/HookForm";

import FileUpload from "../FileUpload/FileUpload";
import TextField from "../TextField/TextField";

import Constant from "../../../util/constant";
import { showToast } from "../../../state/ducks/utils/operations";

import "./AppConfigurations.scss";
import CustomButton from "../CustomButton/CustomButton";
import TextFieldNoRequired from "../TextFieldNoRequired/TextFieldNoRequired";
import GooglePlaceDropDown from "../../GooglePlaceDropDown/GooglePlaceDropDown";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import {
  updatePrivacyPolicy,
  getProfileDetails,
} from "../../../state/ducks/profile/action";
import axios from "axios";
import { getItem, setItem } from "../../../services/localstorage.service";
import moment from "moment";

const AppConfigurations = (props) => {
  let { strings } = props;
  let [image, setImage] = useState();
  const [form, setLoginForm] = useState()
  const [busy, setBusy] = React.useState(false);
  const [isError, setisError] = useState(false);
  const [changeform, setResetForm] = useState();
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [showEditPrivacyPolicy, setShowEditPrivacyPolicy] = useState(false);
  const [portLocation, setPortLocation] = useState(null);
  const [showEmail, setShowEmail] = useState("");
  const [showPhoneNumber, setShowPhoneNumber] = useState("");
  const [showLocation, setShowLocation] = useState("");
  const [longitude, setLongitude] = useState();
  const [latitude, setLatitude] = useState();
  const [currentAddress, setCurrentAddress] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const userDetails = getItem("userDetails");

  useEffect(() => {
    getProfileDetailsData();
  }, []);

  const getProfileDetailsData = async () => {
    try {
      await props.getProfileDetails().then(async (res) => {
        setItem("userDetails", res?.payload);
        let longitude = res?.payload?.location?.coordinates[0];
        setLongitude(longitude);
        let latitude = res?.payload?.location?.coordinates[1];
        setLatitude(latitude);
        let locationAddress = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${Constant.GOOGLEMAPAPI.APIKEY}`
        );
        setEditorState(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(res?.payload?.PrivacyPolicy))
          )
        );
        setCurrentAddress(locationAddress?.data?.results[0]?.formatted_address);
      });
    } catch (err) {
      props.showToast({
        message: get(
          err,
          "response.data.message",
          strings["SOMETHING_WENT_WRONG"]
        ),
        type: "error",
      });
    }
  };
  const onUpdateSubmit = async (e) => {
    let params = {
      contactEmail: e?.email,
      phoneNumber: e?.phoneNumber,
      Address: e?.address,
      countryCode: e?.cunrtyCode,
      longitude: e?.portLocation?.location?.long ? e?.portLocation?.location?.long : longitude,
      latitude: e?.portLocation?.location?.lat ? e?.portLocation?.location?.lat : latitude,
    };
    try {
      setBusy(true);
      await props
        .updatePrivacyPolicy(params)
        .then(async (res) => {
          setItem("userDetails", res?.payload);
          let longitude = res?.payload?.location?.coordinates[0];
          setLongitude(longitude);
          let latitude = res?.payload?.location?.coordinates[1];
          setLatitude(latitude);
          let locationAddress = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${Constant.GOOGLEMAPAPI.APIKEY}`
          );
          setCurrentAddress(
            locationAddress?.data?.results[0]?.formatted_address
          );
          setTimeout(() => {
            setBusy(false);
            props.showToast({
              message: "Contact info has been updated successfully",
              type: "success",
            });
          }, 1000);
          setShowContactDetails(false);
        })
        .catch((err) => {
          setBusy(false);
          props.showToast({
            message: get(
              err,
              "response.data.message",
              strings["SOMETHING_WENT_WRONG"]
            ),
            type: "error",
          });
        });
    } catch (err) {
      setBusy(false);
    }
  };
  const onKeyDown = (e) => {
    e.keyCode === 13 && onFormSubmit();
  };

  const onPrivacyUpdateSubmit = async (e) => {
    let params = {
      PrivacyPolicy: JSON.stringify(e.description),
    };
    try {
      await props.updatePrivacyPolicy(params).then((res) => {
        setItem("userDetails", res?.payload);
        setBusy(true);
        setTimeout(() => {
          setBusy(false);
          props.showToast({
            message: "Privacy policy has been updated successfully",
            type: "success",
          });
        }, 1000);
      });
    } catch (err) {
      setBusy(false);
    }
  };
  const onFormSubmit = async (e) => {
    try {
      setBusy(true);
      setTimeout(() => {
        setBusy(false);
        props.showToast({
          message: "Password has been successfully updated",
          type: "success",
        });
      }, 1000);
    } catch (err) {
      setBusy(false);
    }
  };

  const contactDetailsForm = {
    email: {
      name: "email",
      validate: {
        required: {
          value: true,
          message: strings["EMAIL_REQUIRED"],
        },
        pattern: {
          value: Constant.REGEX.EMAIL,
          message: strings["EMAIL_INVALID"],
        },
      },
    },
    cunrtyCode: {
      name: "cunrtyCode",
      validate: {

      },
    },
    phoneNumber: {
      name: "phoneNumber",
      validate: {
        required: {
          value: true,
          message: strings["PHONE_NUMBER_REQUIRED"],
        },
        maxLength: { value: 13, message: strings["PHONE_NUMBER_INVALID"] },
        minLength: { value: 10, message: strings["PHONE_NUMBER_INVALID"] }
      },
    },
    address: {
      name: "address",
      validate: {
        required: {
          value: true,
          message: strings["ADDRESS_REQUIRED"],
        },
      },
    },
    portLocation: {
      name: "portLocation",
      validate: {
        required: {
          value: false,
        },
      },
    },
  };

  const privacyPolicyForm = {
    description: {
      name: "description",
      validate: {
        pattern: {
          value: /.*/s,
          message: strings["URL_INVALID"],
        },
      },
    },
  };

  return (
    <div className="AppConfigurationsPage">
      {!showContactDetails ? (
        <div className="contact_details">
          <div className="contact_box">
            <div className="title_row">
              <div className="title_txt">Contact details</div>
              <button
                onClick={() => setShowContactDetails(true)}
                className="icon-pencil"
              ></button>
            </div>
            <div className="field_row">
              <div className="input_title">Email address</div>
              <div className="input_txt">
                {userDetails && userDetails.contactEmail}
              </div>
            </div>
            <div className="field_row">
              <div className="input_title">Phone number</div>
              <div className="input_txt">{`+${userDetails?.countryCode ? userDetails?.countryCode : '44'} ${userDetails && userDetails.phoneNumber}`}</div>
            </div>
            <div className="field_row">
              <div className="input_title">Location</div>
              <div className="input_txt outlined">
                {userDetails && userDetails.Address}
              </div>
              <div className="input_txt outlined">
                {userDetails && currentAddress}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="contact_details edit_contact_details">
          <div className="title_txt">Contact details</div>
          <HookForm
            defaultForm={{
              // email: userDetails?.contactEmail,
              // phoneNumber: userDetails?.phoneNumber,
              // address: userDetails?.Address,
            }}
            init={form => setLoginForm(form)}
            onSubmit={(e) => onUpdateSubmit(e)}
          >
            {(formMethod) => {
              return (
                <>
                  <div className="input_title">Email address</div>
                  <TextFieldNoRequired
                    formMethod={formMethod}
                    rules={contactDetailsForm.email.validate}
                    name={contactDetailsForm.email.name}
                    errors={formMethod?.errors}
                    autoFocus={true}
                    defaultValue={userDetails.contactEmail}
                    type="text"
                    autoComplete="on"
                    placeholder={strings["EMAIL_ADDRESS"]}
                  />
                  <div className="input_title">Phone number</div>

                  {/* <div className="phone_view">
                    <div className={formMethod?.errors[contactDetailsForm.phoneNumber.name] !== undefined ? "phone_input_row error_show" : "phone_input_row"}>
                      <Controller
                        // defaultValue={'44'}
                        control={formMethod?.control}
                        rules={contactDetailsForm.phoneNumber.validate}
                        name={contactDetailsForm.phoneNumber.name}
                        errors={formMethod?.errors}
                        defaultValue={userDetails.phoneNumber}
                        render={(props) => (<>
                          <span className="icon-phone"></span>
                          <PhoneInput
                            placeholder="+44"
                            enableSearch="true"
                            disableSearchIcon="false"
                            country={"gb"}
                            defaultMask={"| .........."}
                            alwaysDefaultMask={true}
                            countryCodeEditable={false}
                            value={formMethod.watch(contactDetailsForm.phoneNumber.name)}
                            onChange={props.onChange}
                          />

                        </>)}
                      />
                    </div>
                    {formMethod?.errors[contactDetailsForm.phoneNumber.name] !== undefined ? <div className='errorMsg show'>{formMethod?.errors[contactDetailsForm.phoneNumber.name]['message']}</div> : <div className='errorMsg'>error</div>}
                  </div> */}
                  <div className="phone_view">
                    <div className="countryPhoneGroup">
                      <Controller
                        defaultValue={userDetails?.countryCode ? userDetails?.countryCode : '44'}
                        // defaultValue={"44"}
                        control={formMethod?.control}
                        name={contactDetailsForm.cunrtyCode.name}
                        rules={contactDetailsForm.cunrtyCode.validate}
                        render={(props) => (
                          <>
                            <span className="icon-phone"></span>
                            <PhoneInput
                              country={"gb"}
                              disableSearchIcon="false"
                              placeholder="+44"
                              enableSearch="true"
                              value={formMethod.watch(
                                contactDetailsForm.cunrtyCode.name
                              )}
                              onChange={props.onChange}
                            />
                          </>
                        )}
                      />
                      <TextFieldNoRequired
                        redStar={true}
                        noTextfield={true}
                        onChange={(e) => {
                        }}
                        maskType="99999999999"
                        formMethod={formMethod}
                        rules={contactDetailsForm.phoneNumber.validate}
                        name={contactDetailsForm.phoneNumber.name}
                        errors={formMethod?.errors}
                        placeholder="Phone number"
                        defaultValue={userDetails?.phoneNumber}
                      />
                    </div>
                  </div>
                  <div className="input_title">Location</div>
                  <div className="location_section">
                    <TextFieldNoRequired
                      formMethod={formMethod}
                      rules={contactDetailsForm.address.validate}
                      name={contactDetailsForm.address.name}
                      errors={formMethod?.errors}
                      autoFocus={true}
                      defaultValue={userDetails?.Address}
                      type="text"
                      autoComplete="on"
                      placeholder="Location"
                    />

                    <div className="location_part">
                      <div>
                        <Controller
                          control={formMethod?.control}
                          name={contactDetailsForm.portLocation.name}
                          rules={contactDetailsForm.portLocation.validate}
                          render={({ onChange }) => (
                            <GooglePlaceDropDown
                              defaultValue={{
                                label: currentAddress,
                                value: currentAddress ? currentAddress : {},
                              }}
                              getLocation={(place) => {
                                place === null ? setPortLocation(null) : setPortLocation({
                                  portLocation: {
                                    latitude: get(place, "location.lat", 1),
                                    longitude: get(place, "location.long", 1),
                                    address: get(place, "address", ""),
                                  },
                                });
                                onChange(place);
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="map_view">
                        <iframe
                          title="map"
                          className="map"
                          src={`https://www.google.com/maps/embed/v1/place?key=${Constant.GOOGLEMAPAPI.APIKEY}&q=${get(portLocation, "portLocation.latitude", latitude ? latitude : 1)},${get(portLocation, "portLocation.longitude", longitude ? longitude : 1)}`}
                          width="100%"
                          height="330px"
                          frameBorder="0"
                          allowFullScreen={false}
                          aria-hidden={false}
                          tabIndex={0}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="custombtnfield">
                    <CustomButton
                      type="submit"
                      title={strings["UPDATE"]}
                      disabled={!formMethod?.formState.isValid}
                      loading={busy}
                    />
                    <CustomButton
                      type="button"
                      className="outline_btn"
                      title={strings["CANCEL"]}
                      onClick={() => {
                        setShowContactDetails(false);
                      }}
                    />
                  </div>
                </>
              );
            }}
          </HookForm>
        </div>
      )}
      <div className="other_details">
        <div className="other_title">Other</div>
        <div className="contact_box">
          <div className="title_row">
            <div className="title_txt">Privacy policy</div>
            <button
              onClick={() => setShowEditPrivacyPolicy(true)}
              className="icon-pencil"
            ></button>
          </div>
        </div>
      </div>
      <ConfirmModal
        modalTitle={strings["PRIVACY_POLICY"]}
        leftBtnTitle={strings["UPDATE"]}
        rightBtnTitle={strings["CANCEL"]}
        showModal={showEditPrivacyPolicy}
        onHide={() => setShowEditPrivacyPolicy(false)}
        onProcced={() => setShowEditPrivacyPolicy(false)}
        modalContent={false}
        btnVisible={false}
        className={"edit_privacy_policy"}
      >
        <HookForm defaultForm={{}} onSubmit={(e) => onPrivacyUpdateSubmit(e)}>
          {(formMethod) => {
            return (
              <>
                <div className="date_txt">
                  Last updated:{" "}
                  {moment(userDetails?.PrivacyPolicyUpdateTime).format(
                    "DD MMM YYYY HH:MM A"
                  )}
                </div>
                <div className="textareaBorder">
                  <Controller
                    control={formMethod?.control}
                    name={privacyPolicyForm.description.name}
                    render={(props) => (
                      <Editor
                        placeholder="Privacy policy"
                        editorStyle={{ resize: "vertical" }}
                        toolbar={{
                          options: ["inline", "list", "link"],
                          inline: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ["bold", "italic", "underline"],
                            bold: { className: undefined },
                            italic: { className: undefined },
                            underline: { className: undefined },
                            strikethrough: { className: undefined },
                            monospace: { className: undefined },
                            superscript: { className: undefined },
                            subscript: { className: undefined },
                          },
                          blockType: {
                            inDropdown: true,
                            options: [
                              "Normal",
                              "H1",
                              "H2",
                              "H3",
                              "H4",
                              "H5",
                              "H6",
                              "Blockquote",
                              "Code",
                            ],
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                          },
                          fontSize: {
                            options: [
                              8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60,
                              72, 96,
                            ],
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                          },
                          fontFamily: {
                            options: [
                              "Arial",
                              "Georgia",
                              "Impact",
                              "Tahoma",
                              "Times New Roman",
                              "Verdana",
                            ],
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                          },
                          list: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ["unordered", "ordered"],
                            unordered: { className: undefined },
                            ordered: { className: undefined },
                            indent: { className: undefined },
                            outdent: { className: undefined },
                          },
                          textAlign: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ["left", "center", "right", "justify"],
                            left: { className: undefined },
                            center: { className: undefined },
                            right: { className: undefined },
                            justify: { className: undefined },
                          },
                          link: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            popupClassName: undefined,
                            dropdownClassName: undefined,
                            showOpenOptionOnHover: true,
                            defaultTargetOption: "_self",
                            options: ["link"],
                            link: { className: undefined },
                            unlink: { className: undefined },
                            linkCallback: undefined,
                          },
                          embedded: {
                            className: undefined,
                            component: undefined,
                            popupClassName: undefined,
                            embedCallback: undefined,
                            defaultSize: {
                              height: "auto",
                              width: "auto",
                            },
                          },
                          image: {
                            className: "display-none",
                            component: undefined,
                            popupClassName: undefined,
                            urlEnabled: true,
                            uploadEnabled: true,
                            alignmentEnabled: true,
                            uploadCallback: undefined,
                            previewImage: false,
                            inputAccept:
                              "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                            alt: { present: false, mandatory: false },
                            defaultSize: {
                              height: "0px",
                              width: "0px",
                            },
                          },
                          remove: {
                            className: undefined,
                            component: undefined,
                          },
                          history: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ["undo", "redo"],
                            undo: { className: undefined },
                            redo: { className: undefined },
                          },
                        }}
                        // editorState={this.state.descEditorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="resizeClass"
                        editorState={editorState}
                        // onEditorStateChange={(e) => {
                        //   this.onDescEditorStateChange(e)
                        // }}
                        onEditorStateChange={(e) => {
                          // this.onDescEditorStateChange(e)
                          setEditorState(e);
                        }}
                        onChange={(e) => {
                          formMethod.control.setValue(
                            privacyPolicyForm.description.name,
                            e
                          );
                        }}
                      />
                    )}
                  />
                </div>
                <div className="custombtnfield">
                  <CustomButton
                    type="submit"
                    title={strings["UPDATE"]}
                    disabled={!formMethod?.formState.isValid}
                    loading={busy}
                    onClick={() => {
                      onPrivacyUpdateSubmit(formMethod.getValues());
                    }}
                  />
                  <CustomButton
                    type="button"
                    className="outline_btn"
                    title={strings["CANCEL"]}
                    onClick={() => {
                      setShowEditPrivacyPolicy(false);
                    }}
                  />
                </div>
              </>
            );
          }}
        </HookForm>
      </ConfirmModal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  showToast,
  updatePrivacyPolicy,
  getProfileDetails,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(multilanguage(AppConfigurations))
);