import { get } from "lodash";
import React, { useState, useEffect, useRef } from 'react';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { multilanguage } from "redux-multilanguage";
import Constant from '../../util/constant';
import './GooglePlaceDropDown.scss';
const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const GooglePlaceDropDown = props => {
    let { strings, getLocation, defaultValue } = props;
    const [value, setValue] = useState("New York, NY, USA");
    const prevDefaultValue = usePrevious(defaultValue);

    useEffect(() => {
        if (get(defaultValue, 'label', '') !== get(prevDefaultValue, 'label', ''))
            setValue(defaultValue)
        else if (get(defaultValue, 'label', '') === '') {
            setValue('')
        }
    }, [defaultValue])

    const getLocationAction = async (placeId) => {
        let location = {
            lat: 1,
            long: 1
        }
        try {
            let place = placeId && await geocodeByPlaceId(placeId)
            if (place.length > 0) {
                location['lat'] = place[0].geometry.location.lat()
                location['long'] = place[0].geometry.location.lng()
            }
        } catch (error) {

        }
        return location
    }

    return (
        <div className="googleDropdown">
            <GooglePlacesAutocomplete
                apiKey={Constant.GOOGLEMAPAPI.APIKEY}
                autocompletionRequest={{
                    componentRestrictions: {
                        country: ['uk'],
                    }
                }}
                selectProps={{
                    value,
                    backspaceRemovesValue: true,
                    controlShouldRenderValue: true,
                    isClearable: true,
                    placeholder: strings["ADDRESS"],
                    onChange: async (e) => {
                        setValue(e)
                        if (e) {
                            let location = await getLocationAction(e.value?.place_id)
                            let address = {
                                address: e.label,
                                location
                            }
                            getLocation && getLocation(address)
                        } else {
                            getLocation(null)
                        }
                    }
                }}
            />
        </div>
    )
}

GooglePlaceDropDown.defaultProps = {
}

export default (multilanguage(GooglePlaceDropDown))
