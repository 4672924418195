import React from 'react';
import Dropzone from 'react-dropzone';
import './FileUpload.scss';

const FileUpload = (props) => {
    let { parentClassname, multiple, onDrop, error, setRef } = props;
    return (
        <>
            <div className={parentClassname}>
                <div className={`dropzoneCustom ${error ? 'error' : ''}`}>
                    <Dropzone onDrop={acceptedFiles => { onDrop && onDrop(acceptedFiles) }} ref={setRef}>
                        {({ getRootProps, getInputProps }) => {
                            return <div className="txtDropzone" {...getRootProps()}>
                                <input {...getInputProps()} multiple={multiple} accept="image/png, image/jpg, image/jpeg" />
                                {props.children}
                            </div>
                        }}
                    </Dropzone>
                </div>
            </div>
            {error ? <div className="errorTxtFile"><span className="joyclub-Info"></span><p>{error}</p></div> : null}
        </>
    )
}

FileUpload.defaultProps = {
    hasLabel: true,
    parentClassname: 'fileUploadMain',
    btnVsible: false

}

export default FileUpload;