import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { withRouter, Switch, Route } from 'react-router-dom';
import AdminHeader from '../AdminHeader/AdminHeader';

import { AdminLayoutRoute } from "../../routes";

function AdminLayout(props) {
    return (
        <>
            <AdminHeader />
            <Switch>
                {AdminLayoutRoute.map((route, index) => {
                    return <Route
                        key={index}
                        exact={route.exact}
                        path={route.path}
                        component={route.component}
                    />
                })}
            </Switch>
        </>
    );
}

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminLayout));