import { getParams } from "../../../services/helper.service";
import * as types from "./types";

export const login = (payload) => ({
    type: types.LOGIN,
    meta: {
        async: true,
        blocking: true,
        path: "auth/login",
        method: "POST",
        body: payload
    },
});



export const buyer_list = (params) => {
    return (
        {
            type: types.BUYERS_LIST,
            meta: {
                async: true,
                blocking: true,
                path: `/admin?${getParams(params)}`,
                method: "GET",
            }
        })
}

export const getBuyerdetails = (buyerId) => {
    return (
        {
            type: types.BUYER_DETAILS,
            meta: {
                async: true,
                blocking: true,
                path: `/admin/getbuyerbyid/${buyerId}`,
                method: "GET",
            }
        })
}
// export const enableDisableBuyer = (payload) => ({
//     type: types.ENABLE_BUYER,
//     meta: {
//         async: true,
//         blocking: true,
//         path: `/admin/enabledisablebuyer`,
//         method: "PATCH",
//         body: payload
//     }
// })

export const enableDisableBuyer = (id, payload) => ({
    type: types.DISABLE_BUYER,
    meta: {
        async: true,
        blocking: true,
        path: `/admin/enabledisablebuyer/${id}`,
        method: "PATCH",
        body: payload
    }
})

export const fishermanVerify = (payload) => ({
    type: types.FISHERMAN_VERIFY,
    meta: {
        async: true,
        blocking: true,
        path: `/fishermanVerifyAccount/fishermanverify`,
        method: "POST",
        body: payload
    }
})

// export const buyer_list = (params) => {
//     console.log("Text==>", `/admin?searchText=${getParams(params)}`);
//     return (
//         {
//             type: types.BUYERS_LIST,
//             meta: {
//                 async: true,
//                 blocking: true,
//                 path: `/admin?${getParams(params)}`,
//                 method: "GET",
//             }
//         })
// }

export const deletedBuyer = (id, payload) => ({
    type: types.BUYER_DELETED,
    meta: {
        async: true,
        blocking: true,
        path: `/admin/buyerdeleted/${id}`,
        method: "PATCH",
        body: payload
    }
})

export const disableBuyer = (id, payload) => ({
    type: types.DISABLE_BUYER,
    meta: {
        async: true,
        blocking: true,
        path: `/admin/enabledisablebuyer/${id}`,
        method: "PATCH",
        body: payload
    }
})

export const updateCommission = (id, payload) => ({
    type: types.UPDATE_COMMISSION,
    meta: {
        async: true,
        blocking: true,
        path: `/admin/updatecomission/${id}`,
        method: "PATCH",
        body: payload
    }
})

export const getSpecies = (params) => {
    return (
        {
            type: types.SPECIES_LIST,
            meta: {
                async: true,
                blocking: true,
                path: `/species/getspecieslist?${getParams(params)}`,
                method: "GET"
            }
        })
}

export const enableDisableSpecies = (id, payload) => ({
    type: types.ENABLE_DISABLE_SPECIES,
    meta: {
        async: true,
        blocking: true,
        path: `/species/enabledisablespecies/${id}`,
        method: "PATCH",
        body: payload
    }
})

export const deleteSpecies = (id, payload) => ({
    type: types.DELETE_SPECIES,
    meta: {
        aync: true,
        blocking: true,
        path: `/species/deletespecies/${id}`,
        method: "PATCH",
        body: payload
    }
})

export const logout = () => ({
    type: types.LOGOUT,
});

export const authenticated = () => ({
    type: types.AUTHENTICATED,
});

export const initializeSession = (payload) => ({
    type: types.INITIALIZE,
    payload
});

export const destroySession = () => ({
    type: types.DESTROY
});

export const refreshToken = () => ({
    type: types.REFRESH_TOKEN
})

